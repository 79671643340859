import React from "react"
import styled from "styled-components"
import Logo from "../images/logo.svg"
import { Link } from "gatsby"

import LeftSidebar from "./LeftSidebar"
import RightSidebar from "./RightSidebar"
import toggle from "../images/togglebutton.svg"
import toggleInvert from "../images/toggleButtonInvert.svg"

const StyledWrapper = styled.div`

  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  background-color: white;
  z-index: 9;

  .hamburger {
    outline: none;
  }

  .mobile-header {
    padding-top: 15px;
    padding-bottom: 15px;
    @media (min-width: 992px) {
      display: none;
    }
  }

  .toggle {
    width: 60px;
    padding-left: 15px;
    padding-right: 15px;
    cursor: pointer;
  }

  .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after,
  .hamburger-inner::before {
    width: 25px;
    height: 2px;
  }
`

const MobileHeader = () => {
  const [leftSidebar, setLeftSidebar] = React.useState(false)
  const [rightSidebar, setRightSidebar] = React.useState(false)

  React.useEffect(() => {
    if (leftSidebar || rightSidebar) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "unset"
    }
  }, [leftSidebar, rightSidebar])

  return (
    <StyledWrapper>
      <div className="mobile-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-3 d-flex flex-column justify-content-center">
              {!rightSidebar && (
                <div
                  onClick={() => setLeftSidebar(!leftSidebar)}
                  className={leftSidebar ? "open" : "d-block"}
                  id="menu-toggle"
                >
                  <button
                    className={
                      leftSidebar
                        ? "hamburger hamburger--squeeze is-active justify-content-center"
                        : "hamburger hamburger--squeeze d-flex flex-direction justify-content-center"
                    }
                    type="button"
                  >
                    <span className="hamburger-box">
                      <span className="hamburger-inner"></span>
                    </span>
                  </button>
                </div>
              )}
            </div>
            <div className="col-6 d-flex flex-column justify-content-center">
              <Link to="/">
                <img
                  className="mx-auto d-block img-fluid mt-2"
                  src={Logo}
                  alt=""
                />
              </Link>
            </div>
            <div className="col-3 d-flex flex-column justify-content-center align-items-flex-end">
              {!leftSidebar && (
                <div
                  onClick={() => setRightSidebar(!rightSidebar)}
                  className={
                    rightSidebar
                      ? "open justify-content-end"
                      : "d-flex justify-content-end"
                  }
                  id="menu-toggle"
                >
                  {/* {rightSidebar ? (
                    <img className="toggle float-right" src={toggle} alt="" />
                  ) : (
                    <img
                      className="toggle float-right"
                      src={toggleInvert}
                      alt=""
                    />
                  )} */}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {leftSidebar && (
        <LeftSidebar
          setLeftSidebar={setLeftSidebar}
          leftSidebar={leftSidebar}
        />
      )}
      {/* {rightSidebar && (
        <RightSidebar
          setRightSidebar={setRightSidebar}
          rightSidebar={rightSidebar}
        />
      )} */}
    </StyledWrapper>
  )
}

export default MobileHeader
