import React from "react";
import styled from "styled-components";
import tweet from "../images/SMLogos/7.svg";
import fb from "../images/SMLogos/8.svg";
import ln from "../images/SMLogos/9.svg";
import ig from "../images/SMLogos/10.svg";
import tk from "../images/SMLogos/11.svg";
import email from "../images/SMLogos/email-SM.svg";
import youtube from "../images/SMLogos/12.svg";
import { Link } from "gatsby";
import ReactGA from "react-ga";

const StyledWrapper = styled.div`
  color: ${props => props.theme["secondaryColor"]};
  margin-top: ${props => props.theme["pixel-100"]};
  background-color: ${props => props.theme["borderBottomColor"]};

  .firstmain-byline {
    font-size: 14px;
    color: #ffffff;

    a,
    a:hover,
    a:visited {
      text-decoration: none;
      color: #ffffff;
    }
  }

  .footer-links {
    font-size: ${props => props.theme["FooterLinkSize"]};
    line-height: ${props => props.theme["footerLinkLineHeight"]};
    text-decoration: underline;
    @media (max-width: 600px) {
      font-size: 15px;
    }
  }

  .footer-links:hover {
    color: ${props => props.theme["secondaryColor"]};
  }

  .link-header {
    font-weight: bold;
    text-transform: uppercase;
    @media (max-width: 600px) {
      font-size: 15px;
      font-weight: bold;
    }
  }

  .link-header-border {
    box-sizing: border-box;
    border: 1px solid ${props => props.theme["secondaryColor"]};
  }

  .head-to-head {
    color: white;
    text-decoration: underline;
    @media (max-width: 600px) {
      font-size: 15px;
    }
  }

  .sm-logo {
    width: 60px;
    padding: 15px;
    @media (max-width: 900px) {
      width: 51px;
    }
  }

  .upreach-phn-number {
    color: white;
    text-decoration: underline;
  }

  .col-first {
    font-size: 16px;
    text-transform: uppercase;
    line-height: normal;
    margin-bottom: 15px;
  }
  .logos{
    @media (max-width: 600px) {
      width: 110%;
    }
  }
`;

const Footer = () => {
  const handleContact = () => {
    ReactGA.event({
      category: "Footer",
      action: "Contact Button Click",
    });
  };
  const handleSubscribe = () => {
    ReactGA.event({
      category: "Footer",
      action: "Subscribe Button Click",
    });
  };
  const handleVacancie = () => {
    ReactGA.event({
      category: "Footer",
      action: "Vacancie Button Click",
    });
  };

  return (
    <StyledWrapper>
      <div className="footer container-fluid padding-left-8 padding-right-8 px-4 pt-5">
        <div className="row">
          <div className="col-12 col-md-6 col-lg-4 mb-4 mb-md-0">
            <p className="link-header">ENGAGE</p>
            <hr className="link-header-border mb-3 mb-md-5" />
            <div className="empty__head__col">
              <div>
                <a
                  className="head-to-head"
                  href="mailto:press@upreach.org.uk"
                >
                  <h2 className="col-first">Press Enquiries</h2>
                </a>
              </div>
              <div>
                <Link
                  className="head-to-head"
                  target="_blank"
                  to="http://eepurl.com/glSQQD"
                >
                  <h2 className="col-first" onClick={handleSubscribe}>
                    Subscribe
                  </h2>
                </Link>
              </div>
              <div>
                <Link className="head-to-head" to="/vacancies">
                  <h2 className="col-first" onClick={handleVacancie}>
                    Vacancies
                  </h2>
                </Link>
              </div>
            </div>
            {/* <div className="mt-5 d-none d-sm-block">
                <a
                  className="head-to-head"
                  onClick={handleContact}
                  href="mailto:team@upreach.org.uk"
                >
                  <h2 className="col-first">Email</h2>
                </a>
              </div> */}
              <div className="mt-5 d-none d-sm-block">
              <p className="link-header mb-1">Email</p>
              <a className="upreach-phn-number" href="mailto:team@upreach.org.uk">
              team@upreach.org.uk
              </a>
            </div>
            <div className="mt-3 d-none d-sm-block">
              <p className="link-header mb-1">TELEPHONE</p>
              <a className="upreach-phn-number" href="tel:01618832594">
              01618832594
              </a>
            </div>
            <div className="logos">
            <div className="mt-3 d-none d-sm-block">
              {/* <a href="mailto:team@upreach.org.uk">
                <img className="sm-logo pl-0" src={email} alt="" />
              </a> */}
              <Link target="_blank" to="https://twitter.com/up_Reach">
                <img className="sm-logo pl-0" src={tweet} alt="" />
              </Link>
              <Link target="_blank" to="https://www.facebook.com/upReachUK/">
                <img className="sm-logo pl-0" src={fb} alt="" />
              </Link>
              <Link
                target="_blank"
                to="https://www.linkedin.com/company/upreach/"
              >
                <img className="sm-logo pl-0" src={ln} alt="" />
              </Link>
              <Link
                target="_blank"
                to="https://www.instagram.com/upreach_team/"
              >
                <img className="sm-logo pl-0" src={ig} alt="" />
              </Link>
              <Link target="_blank" to="https://www.tiktok.com/@upreach_team">
                <img className="sm-logo pl-0" src={tk} alt="" />
              </Link>
              <Link target="_blank" to="https://www.youtube.com/@upReach">
                <img className="sm-logo pl-0" src={youtube} alt="" />
              </Link>
            </div>
            </div>
            {/* <div className="mt-0 d-none d-sm-block">
              <p className="firstmain-byline">
                Website built by
                <a
                  href="https://1stmain.co"
                  target="_blank"
                  rel="noopener nofollow"
                >
                  {" "}
                  1st Main
                </a>
              </p>
            </div> */}
          </div>

          {/* <div className="col-6 col-md-4">
            <p className="link-header">MORE ABOUT US</p>
            <hr className="link-header-border mb-5" />
            <div>
              <a className="footer-links" href="">
                
                <p>News & Awards </p>
              </a>
              <a className="footer-links" href="">
                
                <p>Events </p>
              </a>
              <a className="footer-links" href="">
                
                <p>Research </p>
              </a>
              <a className="footer-links" href="">
                
                <p>Press Releases </p>
              </a>
              <a className="footer-links" href="">
                
                <p>Newsletter Archive </p>
              </a>
            </div>
            <div className="d-flex mt-5">
              <a href="">
                
                <img className="img-fluid" src={tweet} alt="" />
              </a>
              <a href="">
                
                <img className="img-fluid mx-3" src={fb} alt="" />
              </a>
              <a href="">
                
                <img className="img-fluid" src={ln} alt="" />
              </a>
            </div>
          </div> */}
          <div className="col-12 col-md-6 col-lg-4 mb-4 mb-md-0">
            <p className="link-header">UPREACH LINKS</p>
            <hr className="link-header-border mb-3 mb-md-5" />
            <div>
              {/* <Link
                className="footer-links"
                target="_blank"
                to="https://aspire.upreach.org.uk"
              >
                <p>Aspire</p>
              </Link> */}
              <Link
                className="footer-links"
                target="_blank"
                to="https://getemployable.org"
              >
                <p>getEmployable</p>
              </Link>
              <Link
                className="footer-links"
                target="_blank"
                to="https://studentsocialmobilityawards.org.uk"
              >
                <p>SSMA</p>
              </Link>
              {/* <Link
                className="footer-links"
                target="_blank"
                to="https://realrating.co.uk"
              >
                <p>REALrating</p>
              </Link> */}
              {/* <Link
                className="footer-links"
                target="_blank"
                to="http://socialmobilitynetwork.org.uk/"
              >
                <p>Social Mobility Network</p>
              </Link> */}
              <Link
                className="footer-links"
                target="_blank"
                to="https://alumni.upreach.org.uk/"
              >
                <p>Alumni</p>
              </Link>
              <Link
                className="footer-links"
                target="_blank"
                to="https://my.upreach.org.uk/"
              >
                <p>my.upReach</p>
              </Link>
            </div>
            <div className="mt-3 mt-md-5">
              <Link
                className="footer-links"
                to="/privacy-notice-and-use-of-cookies"
              >
                <p>Privacy Policy & Cookie Policy</p>
              </Link>
              <Link
                className="footer-links"
                target="_blank"
                to="https://cms.upreach.org.uk/uploads/up_Reach_Associate_Safeguarding_Policy_and_Procedure_665a696bf5.pdf"
              >
                <p>Safeguarding Policy</p>
              </Link>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4 mb-4 mb-md-0">
            <p className="link-header">OFFICES</p>
            <hr className="link-header-border mb-3 mb-md-5" />
            <div>
              <p className="link-header mb-2">LONDON</p>
              <p>
                Second Home,
                <br />
                68-80 Hanbury Street
                <br />
                London, E1 5JL
              </p>
            </div>
            <div>
              <p className="link-header mb-2">MANCHESTER</p>
              <p>
              Spaces, Peter House, Oxford Street, <br />
                Manchester, M1 5AN
              </p>
            </div>
            <div>
              <p className="link-header mb-2">Nottingham</p>
              <p>
                Cubo, Fenchurch House, 12 King St, <br />
                Nottingham NG1 2AS
              </p>
            </div>
            <div>
              <p className="link-header mb-2">Bristol</p>
              <p>
              Floor 8, Office 843, Spaces Castle Park
              Programme, The Pithay, Bristol BS1 2NB
              </p>
            </div>
            <div>
              <p className="link-header mb-2">Newcastle</p>
              <p>
                The Racquets Court, 3 College St, <br />
                Newcastle upon Tyne NE1 8JG
              </p>
            </div>
            <div className="mt-5 d-block d-sm-none">
              <p className="link-header mb-1">Email</p>
              <a className="upreach-phn-number" href="mailto:team@upreach.org.uk">
              team@upreach.org.uk
              </a>
            </div>
            <div className="d-block d-sm-none">
              <p className="link-header mb-1 mt-3">TELEPHONE</p>
              <a className="upreach-phn-number" href="tel:01618832594">
              01618832594
              </a>
            </div>
            <div className="logos">
            <div className="mt-3 d-sm-none d-block">
              {/* <a href="mailto:team@upreach.org.uk">
                <img className="sm-logo pl-0" src={email} alt="" />
              </a> */}
              <Link target="_blank" to="https://twitter.com/up_Reach">
                <img className="sm-logo pl-0" src={tweet} alt="" />
              </Link>
              <Link target="_blank" to="https://www.facebook.com/upReachUK/">
                <img className="sm-logo pl-0" src={fb} alt="" />
              </Link>
              <Link
                target="_blank"
                to="https://www.linkedin.com/company/upreach/"
              >
                <img className="sm-logo pl-0" src={ln} alt="" />
              </Link>
              <Link
                target="_blank"
                to="https://www.instagram.com/upreach_team/"
              >
                <img className="sm-logo pl-0" src={ig} alt="" />
              </Link>
              <Link target="_blank" to="https://www.tiktok.com/@upreach_team">
                <img className="sm-logo pl-0" src={tk} alt="" />
              </Link>
              <Link target="_blank" to="https://www.youtube.com/@upReach">
                <img className="sm-logo pl-0" src={youtube} alt="" />
              </Link>
            </div>
            </div>
            {/* <div className="mt-0 d-block d-sm-none">
              <p className="firstmain-byline">
                Website built by
                <a
                  href="https://1stmain.co"
                  target="_blank"
                  rel="noopener nofollow"
                >
                  {" "}
                  1st Main
                </a>
              </p>
            </div> */}
          </div>
        </div>
        <div className="row mt-3 mt-sm-4 mx-1">
          <p>
            © 2024 upReach Charitable Company | Charity Number 1158896 | Company
            Number 08300398
          </p>
        </div>
      </div>
    </StyledWrapper>
  );
};

export default Footer;
