import { createGlobalStyle } from "styled-components"

const GlobalStyle = createGlobalStyle`

    * {
      -webkit-font-smoothing: antialiased;
      }

      html, body {
        width: ${props => props.theme["percent-100"]};
        height: ${props => props.theme["percent-100"]};
        margin: ${props => props.theme["zero"]};
        color: ${props => props.theme["primaryColor"]};
        font-family: ${props => props.theme["primaryFont"]};
        background-color: ${props => props.theme["GreyBackgroundColor"]};
      }
  
      h1, h2, .subtext {
          letter-spacing: ${props => props.theme["zero"]};
      }
  
      h1 {
          font-size: ${props => props.theme["headingSize"]};
          font-weight: ${props => props.theme["StatisticNumberBold"]};
          line-height: ${props => props.theme["h1LineHeight"]};

        
          @media (max-width: 600px) {
            font-size: 15px;
          }
          @media(min-width:2000px){
            font-size:48px;
          }
      }
      @media(min-width:2000px){
        .subtext,p{
          font-size:24px !important;
          line-height:32px !important;
        }
        li{
          font-size:24px;
          line-height:36px;
        }
      }
  
      h2 {
          font-size: ${props => props.theme["subHeadingSize"]};
          font-weight: ${props => props.theme["StatisticNumberBold"]};
          line-height: ${props => props.theme["h2LineHeight"]};
          
          @media (max-width: 600px) {
            font-size: 14px;
          }
      }
  
      .subtext {
          font-size: ${props => props.theme["subTextSize"]};
          font-weight: ${props => props.theme["textBold"]};
          line-height: ${props => props.theme["subTextLineHeight"]};
          @media (max-width: 600px) {
            font-size: 14px;
            line-height: normal;
          }
      }
  
      .flat-button {
          cursor: pointer;
          box-sizing: border-box;
          text-decoration: underline;
      }
  
      .white-background {
          background-color: ${props => props.theme["secondaryColor"]};
      }
  
      .statistic-number, .statistic-text, .footer-links {
          letter-spacing: ${props => props.theme["zero"]};
          color: ${props => props.theme["secondaryColor"]};
      }
  
      .statistic-number {
          font-size: 70px;
          font-weight: ${props => props.theme["StatisticNumberBold"]};
          line-height: ${props => props.theme["StatisticNumberLineHeight"]};
          @media (max-width: 600px) {
            font-size: ${props => props.theme["headingSize"]} !important;
            line-height: unset !important;
          }
      }
  
      .statistic-text {
          font-size: ${props => props.theme["StatisticTextSize"]};
          line-height: ${props => props.theme["StatisticTextLineHeight"]};
      }
  
      // Sidebars
  
      .sidebar {
        position: absolute;
        top: ${props => props.theme["zero"]};
        height: ${props => props.theme["vh-100"]};
        width: ${props => props.theme["vw-100"]};
        background-color: ${props => props.theme["secondaryColor"]};
        z-index: 5;
      }

      .padding-left-8 {
          padding-left: 8% !important;
      }
      
      .padding-right-8 {
          padding-right: 8% !important;
      }

      .margin-top-100 {
        margin-top: 100px;
        @media (max-width: 600px) {
          margin-top: 50px;
        }
      }
    `

export default GlobalStyle
