import React from "react";
import styled from "styled-components";
import { Link } from "gatsby"
import tweet from "../images/tweet-black.svg"
import fb from "../images/fb-black.svg"
import ln from "../images/ln-black.svg"
import tk from "../images/tk-black.svg"
import youtube from "../images/yt-black.svg"
import Insta from "../images/lnst-black.svg"

const StyledWrapper = styled.div`
  .left-sidebar {
    left: ${(props) => props.theme["zero"]};
    overflow: scroll;
  
    @media (max-width: 450px) {
      top: 75px;
    }

    @media (min-width: 450px) and (max-width: 550px){
      top: 90px;
    }

    @media (min-width: 550px) and (max-width: 599px){
      top: 100px;
    }

    @media (min-width: 600px) and (max-width: 991px) {
      top: 105px;
    }
  }

  .header-link-divider {
    border-bottom: 1px solid ${(props) => props.theme["leftSidebarLineDivider"]};
    max-width: 250px;
    margin-left: ${(props) => props.theme["pixel-50"]};
  }

  .sidebar-internal-links, .sidebar-external-links {
    color: black;
    text-transform: uppercase;
    font-weight: 600;
    line-height: 20px;
    text-decoration: none;
  }
    .sidebar-red-button {
  display: inline-block;
  background-color: rgb(228, 61, 90); /* Button background color */
  color: white !important; /* Ensure text color is white */
  padding: 5px 14px; /* Button padding */
  text-align: center;
  text-decoration: none;
  font-weight: 700;
  margin-top:11px !important;
  line-height: 20px;
}

.sidebar-blue-button {
  display: inline-block;
  background-color: rgb(70, 79, 208); /* Button background color */
  color: white !important; /* Ensure text color is white */
  padding: 5px 14px; /* Button padding */
  text-align: center;
  text-decoration: none;
  font-weight: 700;
  line-height: 20px;
}

  .sm-all-icons {
    margin-left: 25px; 
    // margin-right:30px;
  }

  .active {
    border-bottom: 2px solid #464FD0;
  }

  .sm-black {
  width: 22px; /* Slightly larger for better visibility */
  height: 22px;
}


`;

const SidebarLeft = [
  {
    topLinks: [
      { navLink: "Students", href:"/students", color: "black" },
      { navLink: "Universities", href:"/universities", color: "black" },
      { navLink: "Employers", href:"/employers", color: "black" },
      { navLink: "Get Involved", href:"/involved", color: "black" },
      { navLink: "About", href:"/about", color: "black" },
      { navLink: "News", href: "/news", color:"black"},
      { navLink: "APPLY NOW", href:"/apply-now",color: "#464FD0"},
      { navLink: "DONATE", href:"/donate", color: "#464FD0", isExternal: true },
    ],
  },
  {
    BottomLinks: [
      { navLink: "Latest", href:"/latest" },
      { navLink: "Press Releases", href:"/press-releases" },
      { navLink: "News", href:"/news" },
      { navLink: "Work For Us", href:"/work-for-us" },
      { navLink: "Vacancies", href:"/vacancies" },
    ],
  },
];

const LeftSidebar = ({ setLeftSidebar, leftSidebar, setRightSidebar, rightSidebar }) => {

    return (
    <StyledWrapper>
      <nav className="container-fluid left-sidebar sidebar">
        <div className="row">
          <div className="col-3">

          </div>
        </div>
        <div className="row px-4 mt-5">
          {SidebarLeft[0].topLinks.map((link) => (
            <div className="col-12 last-child-donate mb-2">
              {link.isExternal ?
              <Link style={{color: link.color}} partiallyActive={true}
              className={`${ 
                link.navLink === 'DONATE' ? 'sidebar-blue-button' : 'sidebar-internal-links'
              }`} to={link.href} rel="noreferrer nofollow">
                {link.navLink}
              </Link>
              :(
                  link.navLink === 'APPLY NOW' ? (
                    <Link
                      style={{ color: link.color }}
                      className="sidebar-red-button"
                      to={link.href}
                      partiallyActive={true}
                    >
                      {link.navLink}
                    </Link>
                  ) : (
                    <Link
                      style={{ color: link.color }}
                      className="sidebar-internal-links"
                      to={link.href}
                      partiallyActive={true}
                      activeClassName="active"
                    >
                      {link.navLink}
                    </Link>
                  )
              )}
            </div>
          ))}
        </div>
        <div className="row mt-5 sm-all-icons">
          <Link target="_blank" to="https://twitter.com/up_Reach">
          <img className="sm-black mx-1" src={tweet} alt="https://twitter.com/up_Reach"/>
          </Link>
          <Link target="_blank" to="https://www.facebook.com/upReachUK/">
          <img className="sm-black mx-3" src={fb} alt=""/>
          </Link>
          <Link target="_blank" to="https://www.linkedin.com/company/upreach/">
          <img className="sm-black mx-3" src={ln} alt=""/>
          </Link>
          <Link target="_blank" to="https://www.instagram.com/upreach_team/">
          <img className="sm-black mx-3" src={Insta} alt=""/>
          </Link>
          <Link target="_blank" to="https://www.tiktok.com/@upreach_team">
          <img className="sm-black mx-3" src={tk} alt=""/> 
          </Link>
          <Link target="_blank" to="https://www.youtube.com/@upReach">
          <img className="sm-black  mx-3" src={youtube} alt=""/>
          </Link>
        </div>
      </nav>
    </StyledWrapper>
  );
};

export default LeftSidebar;
